/* eslint-disable no-nested-ternary */
import React, { ReactNode } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { FileUpload as AdaptFileUpload, FileType } from '@angellist/adapt';
import { FileWithPath } from 'react-dropzone';

import isRequired from './util/isRequired';
import AdaptFilePreview from '../../../dashboards/components/common/adapt-documents/AdaptFilePreview';

type FileUploadProps = {
  name: string;
  baseLabel?: string | ReactNode;
  completeLabel?: string | ReactNode;
  isRequired?: boolean;
  multiple?: boolean;
  maxSizeInMb?: number;
  supportedFileTypes?: FileType[];
  shouldUnregister?: boolean;
  state?: 'base' | 'in-progress' | 'complete';
};

declare type FieldProps =
  | {
      onFilesSubmitted?: (files: FileWithPath[]) => void;
      onFileSubmitted?: never;
      multiple: true;
      maxFiles?: number;
    }
  | {
      onFileSubmitted?: (file: FileWithPath) => void;
      onFilesSubmitted?: never;
      maxFiles?: never;
      multiple?: false;
    };

type FileFromServer = {
  documentPath: string;
  documentName: string;
};

export type OtherProps = {
  error?: string;
  isDisabled?: boolean;
  onPressRemove?: () => void;
  disposition?: 'inline' | 'attachment';
};

export type Props = FileUploadProps &
  FieldProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

type FormattedFile = {
  name: string;
  type: string;
  documentPath?: string;
};

const formatValues = (
  value: FileWithPath | FileWithPath[] | FileFromServer | FileFromServer[],
): FormattedFile | FormattedFile[] => {
  const sampleFile = Array.isArray(value) ? value[0] : value;

  if (Object.prototype.hasOwnProperty.call(sampleFile, 'documentName')) {
    const castedValue = value as FileFromServer | FileFromServer[];

    if (Array.isArray(castedValue)) {
      return castedValue.map((file) => ({
        name: file.documentName,
        type: `application/${file.documentName.split('.').pop()}`,
        documentPath: file.documentPath,
      }));
    }
    return {
      name: castedValue.documentName,
      type: `application/${castedValue.documentName.split('.').pop()}`,
      documentPath: castedValue.documentPath,
    };
  }

  const castedValue = value as FileWithPath | FileWithPath[];

  if (Array.isArray(castedValue)) {
    return castedValue.map((file) => ({
      name: file.name,
      type: file.type,
      documentPath: undefined,
    }));
  }
  return {
    name: castedValue.name,
    type: castedValue.type,
    documentPath: undefined,
  };
};

const filePreviewProps = (
  value: FileWithPath | FileWithPath[] | FileFromServer | FileFromServer[],
  disposition: 'inline' | 'attachment',
) => {
  const types: FileType[] = [];

  const formattedValue = formatValues(value);
  const isMultiple = Array.isArray(formattedValue);

  if (isMultiple) {
    types.push(
      ...(formattedValue.map(({ type }) => type.split('/')[1]) as FileType[]),
    );
  } else {
    types.push(formattedValue.type.split('/')[1] as FileType);
  }

  const fileName = isMultiple
    ? formattedValue.map(({ name }) => name).join(', ')
    : formattedValue.name;

  const fileType = types.every((type) => type === types[0])
    ? types[0]
    : undefined;

  const description =
    isMultiple && formattedValue.length > 1
      ? `${formattedValue.length}${`${
          fileType ? ` ${fileType.toUpperCase()}` : ''
        }`} files`
      : undefined;

  const onPressPreview =
    isMultiple || !formattedValue?.documentPath
      ? undefined
      : () => {
          window.open(
            `${formattedValue.documentPath}?disposition=${disposition}`,
          );
        };

  return { fileName, fileType, description, onPressPreview };
};

export const FileUpload = ({
  control,
  defaultValue,
  name,
  error,
  isRequired: isRequiredProp,
  rules,
  shouldUnregister,
  ...inputProps
}: Props) => (
  <Controller
    control={control}
    defaultValue={defaultValue}
    name={name}
    shouldUnregister={shouldUnregister}
    render={({ value, onChange, ...field }) =>
      !isEmpty(value) ? (
        <AdaptFilePreview
          {...inputProps}
          {...filePreviewProps(value, inputProps.disposition ?? 'inline')}
          isDisabled={inputProps.isDisabled}
          onPressRemove={() => {
            onChange(null);
            if (inputProps.onPressRemove) {
              inputProps.onPressRemove();
            }
          }}
        />
      ) : inputProps.multiple ? (
        <AdaptFileUpload
          {...inputProps}
          {...field}
          errorMessage={error || undefined}
          isDisabled={inputProps.isDisabled}
          isRequired={isRequired(rules?.required, isRequiredProp)}
          onFilesSubmitted={(files: FileWithPath[]) => {
            onChange(files);
            if (inputProps.onFilesSubmitted) {
              inputProps.onFilesSubmitted(files);
            }
          }}
        />
      ) : (
        <AdaptFileUpload
          {...inputProps}
          {...field}
          errorMessage={error || undefined}
          isDisabled={inputProps.isDisabled}
          isRequired={isRequired(rules?.required, isRequiredProp)}
          onFileSubmitted={(file) => {
            onChange(file);
            if (inputProps.onFileSubmitted) {
              inputProps.onFileSubmitted(file);
            }
          }}
        />
      )
    }
    rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
  />
);

FileUpload.displayName = 'FileUpload';
