import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';
import { Input as AdaptInput } from '@angellist/adapt';
import { Moment } from 'moment';

import isRequired from './util/isRequired';
import FieldLoader from '../FieldLoader';

type DateType = string | Date | Moment;

type DateInputProps = {
  label?: any;
  name?: string;
  isRequired?: boolean;
  placeholder?: string;
  description?: string | React.ReactNode;
  defaultValue?: DateType;
  autoFocus?: boolean;
  compact?: boolean;
  shouldUnregister?: boolean;
};

type OtherProps = {
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
  compact?: boolean;
};

type Props = DateInputProps & OtherProps & Omit<UseControllerOptions, 'render'>;

export const DateInput = (props: Props) => {
  const {
    name,
    defaultValue,
    rules = { required: false },
    control,
    isRequired: isRequiredProp,
    label,
    error,
    disabled,
    isLoading,
    shouldUnregister,
    compact,
    ...providedInputProps
  } = props;

  if (isLoading) {
    return <FieldLoader compact={compact} />;
  }

  const { validate: validateRules, ...restRules } = rules;

  const dateFieldRule = {
    validate: {
      validate_date: (date?: DateType) => date !== 'Invalid date',
      ...validateRules,
    },
    ...restRules,
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={dateFieldRule}
      shouldUnregister={shouldUnregister}
      render={(field) => {
        const value = field.value ?? '';
        const handleOnChange = (e: any) => {
          field.onChange(e);
        };
        return (
          <AdaptInput
            {...field}
            {...providedInputProps}
            type="date"
            label={label}
            errorMessage={error || undefined}
            isDisabled={disabled}
            value={value}
            compact={compact}
            isRequired={isRequired(rules?.required, isRequiredProp)}
            onChange={handleOnChange}
          />
        );
      }}
    />
  );
};

DateInput.displayName = 'DateInput';
