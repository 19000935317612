import React, { ReactNode } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { Row as AdaptRow } from '@angellist/adapt';

import isRequired from './util/isRequired';
import FieldLoader from '../FieldLoader';

type CheckboxAffix = {
  field: 'checkbox';
};

type SwitchAffix = {
  field: 'switch';
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  isDisabled?: boolean;
  description?: ReactNode | string;
  isFocusVisible?: boolean;
};

export type RowProps = {
  label: string;
  defaultSelected?: boolean;
  isRequired?: boolean;
  divider?: boolean;
  value?: string;
  onChange?: (isSelected: boolean) => void;
  shouldUnregister?: boolean;
  compact?: boolean;
};

export type Props = RowProps & {
  suffix?: Omit<CheckboxAffix, 'value' | 'onChange'> | SwitchAffix;
  prefix?: any;
} & Omit<RowProps, 'suffix'> &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const Row = ({
  control,
  name,
  defaultSelected,
  suffix,
  isRequired: isRequiredProp,
  rules,
  value,
  onChange,
  isDisabled,
  error,
  isLoading,
  shouldUnregister,
  ...inputProps
}: Props) => {
  const selectedValue = value ?? true;
  const unselectedValue = value ? undefined : false;

  if (isLoading) {
    return <FieldLoader />;
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultSelected ? selectedValue : unselectedValue}
      name={name}
      render={(field) => (
        <AdaptRow
          {...field}
          defaultSelected={defaultSelected}
          isSelected={field.value}
          isDisabled={isDisabled}
          isRequired={isRequired(rules?.required, isRequiredProp)}
          suffix={{
            ...suffix,
            errorMessage: error || undefined,
          }}
          onChange={(isSelected) => {
            field.onChange(isSelected ? selectedValue : unselectedValue);
            if (onChange) {
              onChange(isSelected);
            }
          }}
          {...inputProps}
        />
      )}
      rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
      shouldUnregister={shouldUnregister}
    />
  );
};

Row.displayName = 'Row';
