import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { SelectList as AdaptSelectList, Stack } from '@angellist/adapt';

import isRequired from './util/isRequired';
import FieldLoader from '../FieldLoader';

export declare type Orientation = 'horizontal' | 'vertical';

export type ItemType = {
  [x: string]: any;
  label: any;
  description?: any;
  value: any;
  isDisabled?: boolean;
};

export type SelectProps = {
  isRequired?: boolean;
  isDisabled?: boolean;
  selectionMode?: 'single' | 'multiple';
  items: ItemType[];
  onChange?: (value: string) => void;
  orientation?: Orientation;
  columns?: '2' | '3';
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
  defaultSelected?: string | number;
  label?: string;
  name?: string;
  shouldUnregister?: boolean;
};

export type Props = SelectProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const SelectList = ({
  control,
  defaultValue,
  name,
  isRequired: isRequiredProp,
  rules,
  error,
  disabled,
  selectionMode = 'single',
  onChange,
  items,
  isLoading,
  orientation,
  shouldUnregister,
  columns = undefined,
  ...inputProps
}: Props) => {
  if (isLoading) {
    return (
      <Stack gap="100" direction={orientation}>
        {items.map((_, index) => (
          <FieldLoader key={index} />
        ))}
      </Stack>
    );
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      shouldUnregister={shouldUnregister}
      rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
      render={(field) => (
        <AdaptSelectList
          {...field}
          columns={columns}
          aria-label="test"
          selectionMode={selectionMode as any}
          errorMessage={error || undefined}
          isDisabled={disabled}
          isRequired={isRequired(rules?.required, isRequiredProp)}
          value={field.value}
          items={items}
          orientation={orientation}
          onChange={(value: string) => {
            field.onChange(value);
            if (onChange) {
              onChange(value);
            }
          }}
          {...inputProps}
        />
      )}
    />
  );
};

SelectList.displayName = 'SelectList';
