import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { SegmentedControl as AdaptSegmentedControl } from '@angellist/adapt';

type ItemType = { label: string; value: string | number | boolean; icon?: any };

type SegmentedControlProps = {
  isRequired?: boolean;
  onChange?: (e: number | '') => void;
  items: ItemType[];
  shouldUnregister?: boolean;
  compact?: boolean;
  orientation?: 'horizontal' | 'vertical';
  width?: 'fit' | 'fill';
};

export type OtherProps = {
  error?: string;
  disabled?: boolean;
};

export type Props = SegmentedControlProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const SegmentedControl = ({
  control,
  defaultValue,
  name,
  rules,
  disabled,
  error,
  items,
  shouldUnregister,
  ...inputProps
}: Props) => (
  <Controller
    control={control}
    defaultValue={defaultValue}
    name={name}
    shouldUnregister={shouldUnregister}
    render={(field) => (
      <AdaptSegmentedControl
        errorMessage={error || undefined}
        isDisabled={disabled}
        aria-label="Segmented Control"
        onChange={(value: any) => {
          field.onChange(value);
          if (inputProps.onChange) {
            inputProps.onChange(value);
          }
        }}
        items={items}
        {...inputProps}
        {...field}
      />
    )}
    rules={{ ...rules, required: rules?.required ?? inputProps.isRequired }}
  />
);

SegmentedControl.displayName = 'SegmentedControl';
