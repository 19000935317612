import React, { ComponentProps, useState } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { Box, Button, Input as AdaptInput } from '@angellist/adapt';

import isRequired from './util/isRequired';
import FieldLoader from '../FieldLoader';

type SecureInputProps = Pick<ComponentProps<typeof AdaptInput>, 'prefix'> & {
  label?: string;
  name: string;
  isRequired?: boolean;
  placeholder?: string;
  defaultValue?: string | number;
  maxLength?: number;
  onChange?: (e: number | string | '') => void;
  autoFocus?: boolean;
  inputMode?: any;
  shouldUnregister?: boolean;
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
  hasValue?: boolean;
  maskPlaceholder?: string;
  mask?: string | string[];
};

export type Props = SecureInputProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const SecureInput = (props: Props) => {
  const {
    control,
    hasValue,
    defaultValue,
    name,
    isRequired: isRequiredProp,
    rules,
    disabled,
    error,
    onChange,
    isLoading,
    autoFocus,
    placeholder,
    maskPlaceholder = 'XXXXXXXX',
    shouldUnregister,
    mask,
    ...providedInputProps
  } = props;
  const [editActive, setEditActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  if (isLoading) {
    return <FieldLoader />;
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
      shouldUnregister={shouldUnregister}
      render={(field) => {
        const value = field.value ?? '';
        const showIcon = hasValue && !editActive;

        const handleOnChange = (val: string) => {
          field.onChange(val);
          if (onChange) {
            onChange(val);
          }
          setIsDirty(hasValue && !!val);
        };

        const handleOnBlur = () => {
          if (hasValue && !isDirty) {
            setEditActive(false);
          }
        };

        return (
          <Box position="relative" height="full">
            <AdaptInput
              key={editActive ? 'edit-active-cache' : 'not-active-cache'}
              {...field}
              errorMessage={error || undefined}
              isDisabled={disabled || showIcon}
              isRequired={isRequired(rules?.required, isRequiredProp)}
              {...providedInputProps}
              value={value}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              mask={mask}
              placeholder={showIcon ? maskPlaceholder : placeholder}
              autoFocus={autoFocus || editActive}
            />
            {showIcon && (
              <Box
                padding="75"
                position="absolute"
                height="full"
                right="0"
                top="0"
                display="flex"
                alignItems="center"
                gap="75"
              >
                <Button
                  aria-label="Edit"
                  icon="lock"
                  isDisabled={disabled}
                  variant="secondary"
                  onPress={() => setEditActive(true)}
                />
              </Box>
            )}
          </Box>
        );
      }}
    />
  );
};

SecureInput.displayName = 'SecureInput';
