import { UseControllerOptions } from 'react-hook-form';

export default (
  requiredRule?: Required<UseControllerOptions>['rules']['required'],
  override?: boolean,
) =>
  override ||
  (!!requiredRule &&
    (typeof requiredRule === 'string' ||
      requiredRule === true ||
      requiredRule.value));
