import React, { AllHTMLAttributes, ComponentProps, ReactNode } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { Input as AdaptInput } from '@angellist/adapt';

import isRequired from './util/isRequired';
import FieldLoader from '../FieldLoader';

type NativeInputProps = AllHTMLAttributes<HTMLInputElement>;

type InputProps = Pick<ComponentProps<typeof AdaptInput>, 'prefix'> & {
  label?: string;
  description?: string | ReactNode;
  name: string;
  isRequired?: boolean;
  type?: string;
  placeholder?: string;
  defaultValue?: string | number;
  mask?: string | string[];
  maxLength?: number;
  minLength?: number;
  onChange?: (e: number | '') => void;
  step?: NativeInputProps['step'];
  min?: number;
  compact?: boolean;
  autoFocus?: boolean;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  shouldUnregister?: boolean;
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
};

export type Props = InputProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const Input = ({
  control,
  defaultValue,
  name,
  isRequired: isRequiredProp,
  rules,
  type = 'text',
  disabled,
  error,
  onChange,
  isLoading,
  shouldUnregister,
  compact,
  ...providedInputProps
}: Props) => {
  if (isLoading) {
    return <FieldLoader compact={compact} />;
  }

  const inputProps = {
    type,
    ...providedInputProps,
  } as InputProps;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
      shouldUnregister={shouldUnregister}
      render={(field) => {
        const value = field.value ?? '';

        const handleOnChange = (e: any) => {
          field.onChange(e);
          if (onChange) {
            onChange(e);
          }
        };

        return (
          <AdaptInput
            {...field}
            transform={type === 'number' ? 'number' : 'string'}
            errorMessage={error || undefined}
            isDisabled={disabled}
            isRequired={isRequired(rules?.required, isRequiredProp)}
            compact={compact}
            {...inputProps}
            value={value}
            onChange={handleOnChange}
          />
        );
      }}
    />
  );
};

Input.displayName = 'Input';
