import { Box, Stack, Switch, Text } from '@angellist/adapt';
import React, { ReactNode } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

type AccordionSwitchInputProps = {
  name: string;
  label: string;
  control: any;
  watch: any;
  description?: string;
  isDisabled?: boolean;
  error?: string;
  isRequired?: boolean;
  defaultSelected?: boolean;
  children: ReactNode;
  onChange?: (isSelected: boolean) => void;
  shouldUnregister?: boolean;
};

export type Props = AccordionSwitchInputProps &
  Omit<UseControllerOptions, 'render' | 'defaultValue'>;

export const AccordionSwitchInput = (props: Props) => {
  const {
    name,
    label,
    description,
    defaultSelected = false,
    isRequired: isRequiredProp,
    isDisabled,
    rules,
    children,
    error,
    onChange,
    control,
    watch,
    shouldUnregister,
  } = props;
  const isOpen = watch(name, defaultSelected);

  return (
    <Box
      height="fit"
      overflow="hidden"
      borderRadius="container"
      boxShadow="elevation100"
    >
      <Controller
        control={control}
        defaultValue={defaultSelected}
        name={name}
        rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
        shouldUnregister={shouldUnregister}
        render={(field) => (
          <Box
            alignItems="center"
            alignSelf="center"
            backgroundColor={{
              hover: isDisabled ? 'transparent' : 'primary5',
              base: 'transparent',
            }}
            cursor={isDisabled ? 'default' : 'pointer'}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            outline="none"
            transitionDuration="short1"
            transitionTimingFunction="standardEffective"
            transitionProperty="default"
            padding="100"
            borderBottomWidth={isOpen ? 'default' : '0'}
            borderColor={isOpen ? 'border5' : undefined}
            role="button"
            onClick={() => !isDisabled && field.onChange(!field.value)}
          >
            <Stack gap="0" align="baseline">
              <Text color="primaryText" emphasis size="300">
                {label}
              </Text>
              {!!description && (
                <Text color="text70" size="200">
                  {description}
                </Text>
              )}
            </Stack>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              gap="75"
            >
              <Switch
                aria-label={label}
                {...field}
                defaultSelected={defaultSelected}
                errorMessage={error || undefined}
                isDisabled={isDisabled}
                isSelected={!!field.value}
                onChange={(isSelected) => {
                  if (!isDisabled) {
                    field.onChange(isSelected);
                    if (onChange) {
                      onChange(isSelected);
                    }
                  }
                }}
              />
            </Box>
          </Box>
        )}
      />
      <Box
        display="grid"
        transitionTimingFunction="standardEffective"
        transitionDuration="short1"
        aria-hidden={!isOpen}
        style={{ gridTemplateRows: isOpen ? '1fr' : '0fr' }}
      >
        {isOpen && (
          <Box minHeight="0">
            <Box overflow="hidden" padding="100" backgroundColor="surface5">
              {children}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

AccordionSwitchInput.displayName = 'AccordionSwitchInput';
