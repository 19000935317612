import React, { ReactNode } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { Checkbox as AdaptCheckbox } from '@angellist/adapt';

import isRequired from './util/isRequired';

type CheckboxProps = {
  id?: string;
  label?: string | ReactNode;
  defaultSelected?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  isRequired?: boolean;
  onChange?: (isSelected: boolean) => void;
  value?: string;
  verticalAlign?: 'middle' | 'top';
  align?: 'left' | 'right';
  shouldUnregister?: boolean;
};

export type OtherProps = {
  error?: string;
  disabled?: boolean;
};

export type Props = CheckboxProps &
  OtherProps &
  Omit<UseControllerOptions, 'render' | 'defaultValue'>;

export const Checkbox = ({
  control,
  defaultSelected,
  name,
  isRequired: isRequiredProp,
  rules,
  onChange,
  error,
  disabled,
  label,
  shouldUnregister,
  ...inputProps
}: Props) => {
  const selectedValue = inputProps.value ?? true;
  const unselectedValue = inputProps.value ? null : false;

  return (
    <Controller
      control={control}
      defaultValue={defaultSelected ? selectedValue : undefined}
      name={name}
      rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
      shouldUnregister={shouldUnregister}
      render={(field) => (
        <AdaptCheckbox
          {...field}
          defaultSelected={defaultSelected}
          errorMessage={error || undefined}
          isDisabled={disabled}
          isSelected={!!field.value}
          isRequired={isRequired(rules?.required, isRequiredProp)}
          value={inputProps.value}
          onChange={(isSelected) => {
            field.onChange(isSelected ? selectedValue : unselectedValue);
            if (onChange) {
              onChange(isSelected);
            }
          }}
          {...inputProps}
        >
          {label}
        </AdaptCheckbox>
      )}
    />
  );
};

Checkbox.displayName = 'Checkbox';
