import React, { ComponentProps } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { Box, Button, Input as AdaptInput } from '@angellist/adapt';

import isRequired from './util/isRequired';
import { formatToNumber } from '../../utils/number';
import FieldLoader from '../FieldLoader';

type NumberInputProps = Pick<ComponentProps<typeof AdaptInput>, 'prefix'> & {
  label?: string;
  name: string;
  isRequired?: boolean;
  placeholder?: string;
  defaultValue?: string | number;
  maxLength?: number;
  onChange?: (e: number | '') => void;
  min?: number;
  max?: number;
  autoFocus?: boolean;
  shouldUnregister?: boolean;
  compact?: boolean;
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
};

export type Props = NumberInputProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const NumberIncrementerInput = ({
  control,
  defaultValue,
  name,
  isRequired: isRequiredProp,
  rules,
  disabled = false,
  error,
  onChange,
  isLoading,
  min,
  max,
  autoFocus,
  shouldUnregister,
  compact,
  ...providedInputProps
}: Props) => {
  if (isLoading) {
    return <FieldLoader />;
  }
  const hasMaxValue = typeof max === 'number';
  const hasMinValue = typeof min === 'number';

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
      shouldUnregister={shouldUnregister}
      render={(field) => {
        const value = field.value ?? '';
        const updateValue = (numberValue: number) => {
          field.onChange(numberValue);
          if (onChange && field.value !== numberValue) {
            onChange(numberValue);
          }
        };

        const handleOnChange = (val: string) => {
          const numberValue = formatToNumber(val);
          if (typeof numberValue === 'number') {
            if (hasMinValue && numberValue <= min) {
              updateValue(min);
            } else if (hasMaxValue && numberValue >= max) {
              updateValue(max);
            } else {
              updateValue(numberValue);
            }
          } else {
            updateValue(numberValue);
          }
        };

        const handleDecrement = () => {
          const newValue = Number(value || 0) - 1;
          if (hasMinValue && newValue <= min) {
            updateValue(min);
          } else {
            updateValue(newValue);
          }
        };

        const handleIncrement = () => {
          const newValue = Number(value || 0) + 1;
          if (hasMaxValue && newValue >= max) {
            updateValue(max);
          } else {
            updateValue(newValue);
          }
        };

        return (
          <Box position="relative" height="full">
            <AdaptInput
              {...field}
              inputMode="numeric"
              min={min}
              max={max}
              errorMessage={error || undefined}
              isDisabled={disabled}
              compact={compact}
              isRequired={isRequired(rules?.required, isRequiredProp)}
              {...providedInputProps}
              value={value}
              onChange={handleOnChange}
              autoFocus={autoFocus}
            />
            <Box
              padding="75"
              position="absolute"
              height="full"
              right="0"
              top="0"
              display="flex"
              alignItems="center"
              gap={compact ? '0' : '75'}
            >
              <Button
                aria-label="Decrement number"
                icon="minus"
                isDisabled={(hasMinValue && (value || 0) <= min) || disabled}
                variant="secondary"
                size={compact ? 'tiny' : 'base'}
                onPress={handleDecrement}
              />
              <Button
                aria-label="Increment number"
                icon="plus"
                variant="secondary"
                isDisabled={(hasMaxValue && (value || 0) >= max) || disabled}
                size={compact ? 'tiny' : 'base'}
                onPress={handleIncrement}
              />
            </Box>
          </Box>
        );
      }}
    />
  );
};

NumberIncrementerInput.displayName = 'NumberIncrementerInput';
