import React, { AllHTMLAttributes } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { BoxProps, Textarea as AdaptTextarea } from '@angellist/adapt';

import isRequired from './util/isRequired';
import FieldLoader from '../FieldLoader';

type NativeInputProps = AllHTMLAttributes<HTMLInputElement>;

type InputProps = {
  label?: string;
  name: string;
  isRequired?: boolean;
  type?: string;
  placeholder?: string;
  description?: string;
  defaultValue?: string;
  maxLength?: number;
  onChange?: (e: number | '') => void;
  step?: NativeInputProps['step'];
  min?: number;
  isDisabled?: boolean;
  autoFocus?: boolean;
  minHeight?: BoxProps['minHeight'];
  shouldUnregister?: boolean;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
};

export type Props = InputProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const Textarea = ({
  control,
  defaultValue,
  name,
  isRequired: isRequiredProp,
  rules,
  type = 'text',
  isDisabled,
  error,
  isLoading,
  onChange,
  shouldUnregister,
  ...providedInputProps
}: Props) => {
  if (isLoading) {
    return <FieldLoader height="124px" />;
  }

  const inputProps = {
    type,
    ...providedInputProps,
  } as InputProps;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
      shouldUnregister={shouldUnregister}
      render={(field) => {
        const value = field.value ?? '';

        const handleOnChange = (e: any) => {
          field.onChange(e);
          if (onChange) {
            onChange(e);
          }
        };

        return (
          <AdaptTextarea
            {...field}
            errorMessage={error || undefined}
            isDisabled={isDisabled}
            isRequired={isRequired(rules?.required, isRequiredProp)}
            {...inputProps}
            value={value}
            onChange={handleOnChange}
          />
        );
      }}
    />
  );
};

Textarea.displayName = 'Textarea';
