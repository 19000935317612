import React, { AllHTMLAttributes, ComponentProps } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { Input as AdaptInput } from '@angellist/adapt';

import isRequired from './util/isRequired';
import FieldLoader from '../FieldLoader';

type NativeInputProps = AllHTMLAttributes<HTMLInputElement>;

type InputWithSelectProps = Pick<
  ComponentProps<typeof AdaptInput>,
  'prefix'
> & {
  label?: string;
  name: string;
  inputName: string;
  selectName: string;
  selectItems: any[];
  isRequired?: boolean;
  isValueRequired?: boolean;
  type?: string;
  placeholder?: string;
  defaultValue?: string | number;
  defaultSelected?: string | number;
  mask?: string | string[];
  max?: number;
  maxLength?: number;
  minLength?: number;
  onChange?: (e: string | number) => void;
  onSelectChange?: (e: string | number) => void;
  onInputChange?: (e: string | number) => void;
  step?: NativeInputProps['step'];
  min?: number;
  compact?: boolean;
  autoFocus?: boolean;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  shouldUnregister?: boolean;
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
  inputDisabled?: boolean;
  selectDisabled?: boolean;
};

export type Props = InputWithSelectProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const getRules = (
  rules: any,
  isRequiredProp: boolean,
  isValueRequired: boolean,
  inputName: string,
  selectName: string,
) => {
  const { validate: validateRules, required, min, max, ...restRules } =
    rules || {};
  const isFieldRequired = required ?? isRequiredProp;
  const requiredMessage = typeof required === 'string' ? required : '';

  return {
    validate: {
      valueRequired: (values: any) => {
        if (isValueRequired === false) {
          return true;
        }
        if (
          isFieldRequired &&
          (values?.[inputName] === undefined ||
            values?.[inputName] === null ||
            values?.[inputName] === '')
        ) {
          return requiredMessage || 'This field is required';
        }
        return true;
      },
      selectRequired: (values: any) => {
        if (isFieldRequired && !values?.[selectName]) {
          return requiredMessage || 'Please select value';
        }
        return true;
      },
      minRule: (values: any) => {
        if (min) {
          if (
            values?.[inputName] !== undefined &&
            values?.[inputName] < min.value
          ) {
            return min.message;
          }
        }
        return true;
      },
      maxRule: (values: any) => {
        if (max) {
          if (
            values?.[inputName] !== undefined &&
            values?.[inputName] > max.value
          ) {
            return max.message;
          }
        }
        return true;
      },
      ...validateRules,
    },
    ...restRules,
  };
};

export const InputWithSelect = ({
  control,
  defaultValue,
  defaultSelected,
  name,
  inputName,
  selectName,
  selectItems,
  isRequired: isRequiredProp,
  isValueRequired,
  rules,
  type = 'text',
  disabled,
  inputDisabled,
  selectDisabled,
  error,
  onChange,
  isLoading,
  shouldUnregister,
  compact,
  ...providedInputProps
}: Props) => {
  if (isLoading) {
    return <FieldLoader compact={compact} />;
  }

  const inputProps = {
    type,
    ...providedInputProps,
  } as InputWithSelectProps;

  return (
    <Controller
      control={control}
      defaultValue={{
        [inputName]: defaultValue,
        [selectName]: defaultSelected,
      }}
      name={name}
      rules={getRules(
        rules,
        isRequiredProp,
        isValueRequired,
        inputName,
        selectName,
      )}
      shouldUnregister={shouldUnregister}
      render={(field) => {
        const values = field.value;
        const selectValue = (newValues: any) => {
          const controlValues = {
            ...values,
            ...newValues,
          };
          field.onChange(controlValues);
          if (onChange) {
            onChange(controlValues);
          }
        };

        const handleInputOnChange = (value: any) => {
          selectValue({ [inputName]: value });
        };

        const handleSelectOnChange = (value: any) => {
          selectValue({ [selectName]: value });
        };

        return (
          <AdaptInput
            {...field}
            transform={type === 'number' ? 'number' : 'string'}
            errorMessage={error || undefined}
            isDisabled={disabled || inputDisabled}
            namespace={inputName}
            isRequired={isRequired(rules?.required, isRequiredProp)}
            compact={compact}
            {...inputProps}
            value={values?.[inputName]}
            onChange={handleInputOnChange}
            selectProps={{
              items: selectItems,
              namespace: selectName,
              selected: values?.[selectName],
              // @ts-ignore
              isDisabled: disabled || selectDisabled,
              onSelectionChange: handleSelectOnChange,
            }}
          />
        );
      }}
    />
  );
};

InputWithSelect.displayName = 'InputWithSelect';
