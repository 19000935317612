import { Divider, Stack } from '@angellist/adapt';
import React, { ReactNode } from 'react';
import { Row } from './Row';

type Props = {
  name: string;
  label: string;
  control: any;
  watch: any;
  description?: string;
  defaultValue?: boolean;
  children: ReactNode;
  onChange?: (checked: boolean) => void;
  isDisabled?: boolean;
  isDivider?: boolean;
};

export const RowSwitchInput = (props: Props) => {
  const {
    name,
    label,
    description,
    defaultValue = false,
    children,
    onChange,
    control,
    watch,
    isDisabled,
    isDivider = true,
  } = props;
  const watchHasField = watch(name, defaultValue);
  return (
    <Stack gap="100">
      <Row
        control={control}
        label={label}
        defaultSelected={watchHasField}
        description={description}
        name={name}
        suffix={{ field: 'switch' }}
        divider={isDivider && !watchHasField}
        onChange={onChange}
        isDisabled={isDisabled}
        shouldUnregister
      />
      {watchHasField && (
        <>
          {children}
          {isDivider && <Divider size="0" />}
        </>
      )}
    </Stack>
  );
};

RowSwitchInput.displayName = 'RowSwitchInput';
