import React, { AllHTMLAttributes, ComponentProps } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { Input as AdaptInput } from '@angellist/adapt';

import isRequired from './util/isRequired';
import FieldLoader from '../FieldLoader';
import usePhoneCountryCodes from '../../hooks/usePhoneCountryCodes';

type NativeInputProps = AllHTMLAttributes<HTMLInputElement>;

type InputProps = Pick<ComponentProps<typeof AdaptInput>, 'prefix'> & {
  label?: string;
  name: string;
  phoneCountryCodeName?: string;
  isRequired?: boolean;
  type?: string;
  placeholder?: string;
  defaultValue?: {
    number: string | number;
    countryCode: string | number;
  };
  maxLength?: number;
  onChange?: (e: number | '') => void;
  step?: NativeInputProps['step'];
  autoFocus?: boolean;
  shouldUnregister?: boolean;
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
};

export type Props = InputProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const PhoneInput = ({
  control,
  defaultValue,
  name,
  isRequired: isRequiredProp,
  rules,
  disabled,
  error,
  onChange,
  isLoading,
  autoFocus,
  maxLength,
  shouldUnregister,
  ...providedInputProps
}: Props) => {
  const [codesLoading, phoneCountryCodes] = usePhoneCountryCodes();
  const isFieldRequired = rules?.required ?? isRequiredProp;

  if (isLoading || codesLoading) {
    return <FieldLoader />;
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultValue || { number: '', countryCode: '' }}
      name={name}
      shouldUnregister={shouldUnregister}
      rules={{
        validate: {
          numberRequired: (values: any) => {
            if (isFieldRequired && !values?.number) {
              return 'Phone number is required';
            }
            return true;
          },
          codeRequired: (values: any) => {
            if (isFieldRequired && !values?.countryCode) {
              return 'Country code is required';
            }
            return true;
          },
          maxLength: (values: any) => {
            if (maxLength === null || maxLength === undefined) return true;
            if ((values?.number?.length || 0) > maxLength) {
              return `Phone number must not exceed ${maxLength} digits`;
            }
            return true;
          },
        },
      }}
      render={(field) => {
        const { number, countryCode } = field.value ?? {
          number: '',
          countryCode: '',
        };

        const updateValue = (fieldValue: any) => {
          field.onChange(fieldValue);
          if (onChange) {
            onChange(fieldValue);
          }
        };

        const handleOnChange = (newNumber: any) => {
          updateValue({
            ...field.value,
            number: newNumber,
          });
        };

        const handleCodeChange = (newCode: any) => {
          updateValue({
            ...field.value,
            countryCode: newCode,
          });
        };

        return (
          // @ts-ignore
          <AdaptInput
            {...field}
            // @ts-ignore
            type="tel"
            inputMode="numeric"
            namespace="number"
            maxLength={maxLength}
            errorMessage={error || undefined}
            isDisabled={disabled}
            isRequired={isRequired(rules?.required, isRequiredProp)}
            {...providedInputProps}
            value={number}
            onChange={handleOnChange}
            autoFocus={autoFocus}
            selectProps={{
              namespace: 'countryCode',
              items: phoneCountryCodes,
              selected: countryCode,
              defaultSelected: defaultValue?.countryCode,
              onSelectionChange: handleCodeChange,
            }}
          />
        );
      }}
    />
  );
};

PhoneInput.displayName = 'PhoneInput';
