import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { MoneyInput as AdaptMoneyInput } from '@angellist/adapt';

import isRequired from './util/isRequired';
import FieldLoader from '../FieldLoader';
import { getCentCurrency, getMicroCentCurrency } from '../../utils/number';
import { Money } from '../../../graphql';

type Currency = any;

type InputValueType = Pick<Money, 'currency' | 'fractional'> | '';

type MoneyInputProps = {
  label?: string;
  compact?: boolean;
  currencies?: Currency[] | 'all';
  currency?: Currency;
  isRequired?: boolean;
  isDisabled?: boolean;
  precision?: string;
  description?: string;
  defaultCurrency?: string;
  defaultFractional?: number;
  hideSuffix?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  onChange?: (money: Pick<Money, 'currency' | 'fractional'> | '') => void;
  onCurrencyChange?: (currency: Currency) => void;
  onFractionalChange?: (fractional: number | '') => void;
  shouldUnregister?: boolean;
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  supportMicroCent?: boolean;
};

type Props = MoneyInputProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const getRules = (rules: any, isRequiredProp: boolean) => {
  const { validate: validateRules, min, max, required, ...restRules } =
    rules || {};
  const isFieldRequired = required ?? isRequiredProp;

  return {
    validate: {
      fractionalRequired: (values: any) => {
        if (
          isFieldRequired &&
          (values?.fractional === undefined || values?.fractional === '')
        ) {
          return 'Amount is required';
        }
        return true;
      },
      currencyRequired: (values: any) => {
        if (isFieldRequired && !values?.currency) {
          return 'Currency is required';
        }
        return true;
      },
      minRule: (values: any) => {
        if (min) {
          if (
            values?.fractional !== undefined &&
            values?.fractional < min.value
          ) {
            return min.message;
          }
        }
        return true;
      },
      maxRule: (values: any) => {
        if (max && isFieldRequired) {
          if (
            values?.fractional !== undefined &&
            values?.fractional > max.value
          ) {
            return max.message;
          }
        }
        return true;
      },
      ...validateRules,
    },
    ...restRules,
  };
};

export const MoneyInput = ({
  control,
  name,
  rules,
  isRequired: isRequiredProp,
  error,
  currency: currencyProp,
  isLoading,
  isDisabled,
  onChange,
  onCurrencyChange,
  onFractionalChange,
  label,
  shouldUnregister,
  defaultCurrency,
  defaultFractional,
  supportMicroCent,
  precision,
  hideSuffix,
  compact,
  ...moneyInputProps
}: Props) => {
  if (isLoading) {
    return <FieldLoader compact={compact} />;
  }

  const inputPrecision = supportMicroCent ? '0.0000001' : precision;
  const getCurrencyValue = (currency: string) => {
    if (supportMicroCent) {
      return getMicroCentCurrency(getCentCurrency(currency));
    }
    return currency;
  };

  const inputProps: any = {};
  if (hideSuffix) {
    inputProps.selectProps = undefined;
  }

  return (
    <Controller
      control={control}
      defaultValue={{
        currency: getCurrencyValue(defaultCurrency),
        fractional: defaultFractional,
      }}
      name={name}
      rules={getRules(rules, isRequiredProp)}
      shouldUnregister={shouldUnregister}
      render={(field) => {
        const { value } = field;
        const handleChange = (inputValue: InputValueType) => {
          let values: InputValueType = inputValue;
          if (values) {
            values.currency = getCurrencyValue(values.currency);
          } else {
            values = {
              fractional: undefined,
              currency: getCurrencyValue(defaultCurrency),
            };
          }
          field.onChange(values);
          if (onChange) {
            onChange(values);
          }
        };

        const handleCurrencyChange = (newCurrency: Currency) => {
          const currency = getCurrencyValue(newCurrency);
          field.onChange({
            fractional: value?.fractional,
            currency,
          });
          if (onCurrencyChange) {
            onCurrencyChange(currency);
          }
        };

        const handleFractionalChange = (newFractional: number | '') => {
          field.onChange({
            fractional: newFractional,
            currency: getCurrencyValue(value?.currency),
          });
          if (onFractionalChange) {
            onFractionalChange(newFractional);
          }
        };

        return (
          <AdaptMoneyInput
            aria-label={label}
            label={label}
            currencies={!currencyProp ? 'all' : undefined}
            compact={compact}
            currency={
              getCentCurrency(value?.currency || currencyProp) as Currency
            }
            errorMessage={error || undefined}
            fractional={value?.fractional}
            isDisabled={isDisabled}
            precision={inputPrecision}
            isRequired={isRequired(rules?.required, isRequiredProp)}
            onChange={handleChange}
            onCurrencyChange={handleCurrencyChange}
            onFractionalChange={handleFractionalChange}
            {...inputProps}
            {...moneyInputProps}
          />
        );
      }}
    />
  );
};

MoneyInput.displayName = 'MoneyInput';
