import React from 'react';
import useCountryCodes from '../../../dashboards/components/common/hooks/useCountryCodes';
import { Select, Props as SelectProps } from './Select';

type Props = {} & Omit<SelectProps, 'items'>;

export const CountrySelect = (props: Props) => {
  const { isLoading, control, name, label, ...restSelectProps } = props;
  const { loading: countryLoading, countryCodeOptions } = useCountryCodes();

  return (
    <Select
      name={name}
      label={label}
      control={control}
      {...restSelectProps}
      isLoading={isLoading || countryLoading}
      items={countryCodeOptions}
    />
  );
};

CountrySelect.defaultProps = {
  loading: false,
};
