const onNumberInputFilter = (v?: string, decimalsLimit = 2) => {
  if (!v || v === '.' || v === '-') return v;

  // Remove anything that's not a digit, a period, or a negative sign
  const filtered = v.replace(/[^-\d\\.]/g, '');

  const segments = filtered.split('.');
  const integerPart = Number(segments[0]).toLocaleString('en-US');
  const decimalPart = [null, undefined].includes(segments[1])
    ? undefined
    : segments[1].substring(0, decimalsLimit);

  return decimalPart !== undefined && decimalsLimit > 0
    ? `${integerPart}.${decimalPart}`
    : integerPart;
};

export default onNumberInputFilter;
