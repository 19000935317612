import React, { ReactNode } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { Switch as AdaptSwitch } from '@angellist/adapt';

type SwitchProps = {
  id?: string;
  label?: string | ReactNode;
  defaultSelected?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  isRequired?: boolean;
  onChange?: (isSelected: boolean) => void;
  verticalAlign?: 'middle' | 'top';
  align?: 'left' | 'right';
  shouldUnregister?: boolean;
};

export type OtherProps = {
  error?: string;
  disabled?: boolean;
};

export type Props = SwitchProps &
  OtherProps &
  Omit<UseControllerOptions, 'render' | 'defaultValue'>;

export const Switch = ({
  control,
  defaultSelected,
  name,
  isRequired: isRequiredProp,
  rules,
  label,
  onChange,
  error,
  disabled,
  shouldUnregister,
  ...inputProps
}: Props) => (
  <Controller
    control={control}
    defaultValue={defaultSelected}
    name={name}
    rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
    shouldUnregister={shouldUnregister}
    render={(field) => (
      <AdaptSwitch
        {...field}
        defaultSelected={defaultSelected}
        errorMessage={error || undefined}
        isDisabled={disabled}
        isSelected={!!field.value}
        onChange={(isSelected) => {
          field.onChange(isSelected);
          if (onChange) {
            onChange(isSelected);
          }
        }}
        {...inputProps}
      >
        {label}
      </AdaptSwitch>
    )}
  />
);

Switch.displayName = 'Switch';
