import React, { ReactNode } from 'react';
import { Box, Text } from '@angellist/adapt';

type Props = {
  isDisabled?: boolean;
  boxProps?: any;
  icon: ReactNode;
  title: ReactNode | string;
  description?: ReactNode | string;
  suffix?: ReactNode;
};

const CustomPreviewBox = (props: Props) => {
  const { isDisabled, icon, title, description, suffix, boxProps } = props;
  return (
    <Box
      as="div"
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap="100"
      minWidth="full"
      padding="100"
      position="relative"
      {...boxProps}
    >
      {icon}
      <Box
        alignItems="flex-start"
        flex="1"
        flexDirection="column"
        justifyContent="center"
        lineHeight="text200"
        minWidth="0"
      >
        <Text ellipsis lineHeight="inherit" weight={'medium' as 'medium'}>
          {title}
        </Text>
        {!!description && (
          <Text
            color="text70"
            ellipsis
            whiteSpace="pre-wrap"
            lineHeight="inherit"
          >
            {description}
          </Text>
        )}
      </Box>
      {suffix}
      {isDisabled && (
        <Box
          position="absolute"
          positionOffset="0"
          backgroundColor="base"
          opacity="60"
          transitionDuration="150"
          transitionTimingFunction="inOut"
          cursor="not-allowed"
        />
      )}
    </Box>
  );
};

export default CustomPreviewBox;
