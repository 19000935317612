import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { Select as AdaptSelect } from '@angellist/adapt';

import isRequired from './util/isRequired';
import FieldLoader from '../FieldLoader';

export type ItemType = {
  [x: string]: any;
  label: any;
};

export type SelectProps = {
  defaultSelected?: string | number;
  label?: string;
  name?: string;
  as?: 'button' | 'input';
  isRequired?: boolean;
  prefix?: any;
  compact?: boolean;
  selected?: string | number;
  items: ItemType[];
  placeholder?: string;
  onSelectionChange?: (value: string | number) => void;
  autoFocus?: boolean;
  shouldUnregister?: boolean;
  description?: string;
  createItem?: React.ComponentProps<typeof AdaptSelect>['createItem'];
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
};

export type Props = SelectProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const Select = ({
  control,
  defaultValue,
  name,
  isRequired: isRequiredProp,
  rules,
  onSelectionChange,
  error,
  disabled,
  isLoading,
  shouldUnregister,
  compact,
  ...inputProps
}: Props) => {
  if (isLoading) {
    return <FieldLoader compact={compact} />;
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
      shouldUnregister={shouldUnregister}
      render={(field) => (
        <AdaptSelect
          {...field}
          errorMessage={error || undefined}
          isDisabled={disabled}
          isRequired={isRequired(rules?.required, isRequiredProp)}
          selected={field.value}
          compact={compact}
          // @ts-ignore
          defaultSelected={defaultValue}
          onSelectionChange={(value) => {
            field.onChange(value);
            if (onSelectionChange) {
              onSelectionChange(value);
            }
          }}
          {...inputProps}
        />
      )}
    />
  );
};

Select.displayName = 'Select';
