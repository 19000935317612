import { Icon } from '@angellist/adapt';
import React from 'react';

export const fileIcon = (
  fileType = '',
  icon?: any,
  name?: any,
  color?: any,
) => {
  // Use top level props if provided, else use nested ones under Icon
  const iconName = icon?.name ?? name;
  const iconColor = icon?.color ?? color;
  const iconContainerColor = icon?.containerColor ?? 'surface10';

  if (iconName)
    return (
      <Icon
        backgroundColor={iconContainerColor}
        color={iconColor}
        container="box"
        named={iconName}
      />
    );

  const iconProps: any = {
    named: 'documentPlain',
    container: 'box',
    backgroundColor: iconContainerColor,
    ...(iconColor && { color: iconColor }),
  };

  switch (fileType) {
    case 'csv':
    case 'xls':
    case 'xlsx':
      iconProps.named = 'csv';
      break;
    case 'doc':
    case 'docx':
    case 'txt':
      iconProps.named = 'memo';
      break;
    case 'pdf':
      iconProps.named = 'pdf';
      iconProps.color = 'danger';
      break;
    case 'jpeg':
    case 'jpg':
    case 'png':
      iconProps.named = 'image';
      break;
    case 'ppt':
    case 'pptx':
      iconProps.named = 'deck';
      break;
    case 'zip':
      iconProps.named = 'attachment';
      break;
    default:
  }

  return <Icon {...iconProps} />;
};
