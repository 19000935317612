import { Box, Input, Select, Stack } from '@angellist/adapt';
import React, { ComponentProps } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { CURRENCY_MAP } from '../../constants/currency';
import FieldLoader from '../FieldLoader';

import onNumberInputFilter from './util/onNumberInputFilter';

// Adapt doesn't have a money input yet (which permits currency selection) - this is a stop-gap
// until one exists
export const MoneyInput = ({
  control,
  currencyName,
  error,
  isDisabled,
  label,
  rules,
  valueName,
  prefix,
  placeholder,
  defaultValue,
  defaultCurrencyValue,
  autoFocus,
  isLoading,
  decimalsLimit = 2,
  shouldUnregister,
  ...otherProps
}: Props) => {
  const currencyCodes = Object.keys(CURRENCY_MAP).filter(
    (code) => !code.endsWith('8'), // Filter out the microcent currencies
  );

  const items = currencyCodes.map((code) => ({
    value: code,
    label: code,
    'aria-label': CURRENCY_MAP[code as keyof typeof CURRENCY_MAP],
  }));

  return (
    <Stack direction="horizontal" gap="50">
      {currencyName && (
        <Box style={{ width: '89px' }}>
          {isLoading ? (
            <FieldLoader />
          ) : (
            <Controller
              control={control}
              name={currencyName}
              rules={rules}
              defaultValue={defaultCurrencyValue}
              shouldUnregister={shouldUnregister}
              render={({ onChange, value, ...fieldProps }) => (
                <Select
                  {...fieldProps}
                  label={value}
                  items={items}
                  isDisabled={isDisabled}
                  selected={value}
                  onSelectionChange={onChange}
                  {...otherProps}
                />
              )}
            />
          )}
        </Box>
      )}
      <Box flex="1">
        {isLoading ? (
          <FieldLoader />
        ) : (
          <Controller
            control={control}
            name={valueName}
            rules={rules}
            defaultValue={defaultValue}
            shouldUnregister={shouldUnregister}
            render={({ onChange, value, ...fieldProps }) => (
              // @ts-ignore
              <Input
                {...fieldProps}
                prefix={prefix}
                label={label}
                errorMessage={error || undefined}
                isDisabled={isDisabled}
                {...otherProps}
                value={value ?? ''}
                onChange={onChange}
                placeholder={placeholder}
                onInputFilter={(v) => onNumberInputFilter(v, decimalsLimit)}
                autoFocus={autoFocus}
              />
            )}
          />
        )}
      </Box>
    </Stack>
  );
};

interface Props
  extends Pick<
      ComponentProps<typeof Select>,
      'label' | 'placeholder' | 'isDisabled'
    >,
    Omit<UseControllerOptions, 'render' | 'name'> {
  valueName: string;
  currencyName?: string;
  error?: string;
  prefix?: ComponentProps<typeof Input>['prefix'];
  defaultValue?: number;
  defaultCurrencyValue?: string;
  autoFocus?: boolean;
  isLoading?: boolean;
  decimalsLimit?: number;
  shouldUnregister?: boolean;
}

MoneyInput.displayName = 'MoneyInput';
