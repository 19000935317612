import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { ComboBox as AdaptComboBox } from '@angellist/adapt';

import isRequired from './util/isRequired';
import FieldLoader from '../FieldLoader';

type ComboBoxItem = {
  value: any;
  label: any;
};

export type ComboBoxProps = {
  defaultSelected?: ComboBoxItem;
  label?: string;
  name?: string;
  isRequired?: boolean;
  items: ComboBoxItem[];
  placeholder?: string;
  autoFocus?: boolean;
  shouldUnregister?: boolean;
  description?: string;
};

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  disabled?: boolean;
};

export type Props = ComboBoxProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const ComboBox = ({
  control,
  defaultValue,
  name,
  isRequired: isRequiredProp,
  rules,
  error,
  disabled,
  isLoading,
  shouldUnregister,
  items,
  ...inputProps
}: Props) => {
  if (isLoading) {
    return <FieldLoader />;
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
      shouldUnregister={shouldUnregister}
      render={(field) => {
        const selected =
          items.find((item) => item.value === field.value) || undefined;
        return (
          <AdaptComboBox
            {...field}
            errorMessage={error || undefined}
            isDisabled={disabled}
            isRequired={isRequired(rules?.required, isRequiredProp)}
            selected={selected}
            onSelectionChange={(item) =>
              typeof item === 'string'
                ? field.onChange('')
                : field.onChange(item.value)
            }
            getItems={(filterText) =>
              items.filter(({ label }) =>
                label.toLowerCase().includes(filterText.toLowerCase()),
              )
            }
            {...inputProps}
          />
        );
      }}
    />
  );
};

ComboBox.displayName = 'ComboBox';
