import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

type HiddenInputProps = {
  name: string;
  defaultValue?: string | number;
  shouldUnregister?: boolean;
};

export type OtherProps = {
  isLoading?: boolean;
};

export type Props = HiddenInputProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const HiddenInput = ({
  control,
  defaultValue,
  name,
  rules,
  isLoading,
  shouldUnregister,
}: Props) => {
  if (isLoading) {
    return null;
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{ ...rules, required: rules?.required }}
      shouldUnregister={shouldUnregister}
      render={(field) => (
        <input type="hidden" name={name} value={field.value || ''} />
      )}
    />
  );
};

HiddenInput.displayName = 'HiddenInput';
