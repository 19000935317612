import React, { ReactNode } from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';

import { Row as AdaptRow, Box, Stack, Text } from '@angellist/adapt';

import isRequired from './util/isRequired';
import RowLoader from '../../../dashboards/components/common/RowLoader';

export type OtherProps = {
  isLoading?: boolean;
  error?: string;
  isDisabled?: boolean;
  items: {
    label: string;
    value: string;
    description?: ReactNode | string;
  }[];
  numOfLoadingRows?: number;
  onSelectionChange?: (value: string | number) => void;
};

export type RowProps = {
  isRequired?: boolean;
  divider?: boolean;
  defaultValue?: string;
  shouldUnregister?: boolean;
};

export type Props = RowProps &
  OtherProps &
  Omit<UseControllerOptions, 'render'>;

export const RadioRow = ({
  control,
  name,
  isRequired: isRequiredProp,
  rules,
  defaultValue,
  isDisabled,
  error,
  isLoading,
  shouldUnregister,
  numOfLoadingRows = 2,
  items,
  onSelectionChange,
}: Props) => {
  if (isLoading) {
    return (
      <Stack gap="0">
        {Array(numOfLoadingRows)
          .fill(0)
          .map((_, index) => (
            <AdaptRow
              key={index}
              // @ts-ignore
              label={<RowLoader height={12} />}
              isDisabled
              suffix={{ field: 'radio' }}
            />
          ))}
      </Stack>
    );
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={(field) => (
        <Stack gap="0">
          {items.map((item) => (
            <AdaptRow
              key={item.value}
              label={item.label}
              description={item.description}
              defaultSelected={false}
              isRequired={isRequired(rules?.required, isRequiredProp)}
              suffix={{
                field: 'radio',
                defaultSelected: item.value === defaultValue,
                isDisabled,
                isSelected: item.value === field.value,
              }}
              onChange={() => {
                field.onChange(item.value);
                if (onSelectionChange) {
                  onSelectionChange(item.value);
                }
              }}
            />
          ))}
          {!!error && (
            <Box paddingTop="50">
              <Text size="200" color="danger">
                {error}
              </Text>
            </Box>
          )}
        </Stack>
      )}
      rules={{ ...rules, required: rules?.required ?? isRequiredProp }}
      shouldUnregister={shouldUnregister}
    />
  );
};

RadioRow.displayName = 'RadioRow';
