import React from 'react';
import { Button, Menu, Stack } from '@angellist/adapt';

import { compact } from 'lodash';
import { FileMenuProps, FilePreViewProps } from './index.types';
import { fileIcon } from './utils';
import MenuItemText from '../MenuItemText';
import { StopPropagationEvent } from '../hoc';
import CustomPreviewBox from '../CustomPreviewBox';

export const FileActions = (props: FileMenuProps) => {
  const {
    href,
    target = '_blank',
    onPressDownload,
    onPressPreview,
    onPressRemove,
  } = props;
  const canRemove = !!onPressRemove;
  const canPress = !!onPressPreview;
  const canDownload = !!onPressDownload;
  const canLinkDownload = !!href;
  const items = compact([canRemove, canPress, canDownload, canLinkDownload]);

  if (items.length) {
    if (items.length === 1) {
      return (
        <StopPropagationEvent>
          <Stack direction="horizontal">
            {(canDownload || canLinkDownload) && (
              <Button
                aria-label="Download"
                as={href ? 'a' : 'button'}
                href={href}
                icon="download"
                linkOptions={{ isExternalLink: !!href }}
                target={href ? target : undefined}
                variant="secondary"
                onPress={onPressDownload}
              />
            )}
            {canRemove && (
              <Button
                aria-label="Remove"
                icon="trashCan"
                variant="secondary"
                onPress={onPressRemove}
              />
            )}
            {canPress && (
              <Button
                aria-label="Preview"
                icon="documentPreview"
                variant="secondary"
                onPress={onPressPreview}
              />
            )}
          </Stack>
        </StopPropagationEvent>
      );
    }
    const handleMenuAction = (key: string) => {
      if (key === 'remove') {
        onPressRemove();
      }
      if (key === 'preview') {
        onPressPreview();
      }
      if (key === 'download') {
        onPressDownload();
      }
    };

    return (
      <StopPropagationEvent>
        <Menu.Button
          triggerElement={
            <Button aria-label="More" icon="more" variant="ghost" />
          }
          onAction={handleMenuAction}
          placement="bottom end"
          overlayProps={{
            className: 'unified-nav',
          }}
        >
          {canPress && (
            <Menu.Item key="preview">
              <MenuItemText label="Preview" icon="documentPreview" />
            </Menu.Item>
          )}
          {canDownload && (
            <Menu.Item key="download">
              <MenuItemText label="Download" icon="download" />
            </Menu.Item>
          )}
          {canLinkDownload && (
            <Menu.Item
              key="download"
              linkOptions={{ isExternalLink: true }}
              href={href}
              target={target}
            >
              <MenuItemText label="Download" icon="download" />
            </Menu.Item>
          )}
          {canRemove && (
            <Menu.Item key="remove">
              <MenuItemText label="Remove" icon="trashCan" />
            </Menu.Item>
          )}
        </Menu.Button>
      </StopPropagationEvent>
    );
  }

  return null;
};

const AdaptFilePreview = (props: FilePreViewProps) => {
  const {
    description,
    fileUploadedAt,
    icon,
    iconName,
    iconColor,
    isDisabled,
    fileType: fileTypeProp,
    fileName = '',
    hideDescription,
    boxProps = {
      borderRadius: 'container',
      borderColor: 'border10',
      borderWidth: 'default',
    },
    borderWidth,
    ...fileMenuProps
  } = props;

  const updatedBoxProps = {
    ...boxProps,
    borderWidth: borderWidth || boxProps.borderWidth,
  };

  const formatDate = (date: Date) =>
    date.toLocaleDateString([], {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });

  const fileType = fileTypeProp ?? fileName.split('.').pop();
  let customDescription: string = '';
  if (!hideDescription) {
    customDescription = description;
    if (!description && (fileType || fileUploadedAt)) {
      const items = [];
      if (fileType) {
        items.push(`${fileType?.toUpperCase()} file`);
        if (fileUploadedAt) {
          items.push(' • ');
        }
      }
      if (fileUploadedAt) {
        items.push(`Uploaded ${formatDate(fileUploadedAt)}`);
      }
      customDescription = items.join(' ');
    }
  }

  return (
    <CustomPreviewBox
      isDisabled={isDisabled}
      boxProps={updatedBoxProps}
      icon={fileIcon(fileType, icon, iconName, iconColor)}
      title={fileName}
      description={customDescription}
      suffix={<FileActions {...fileMenuProps} />}
    />
  );
};

export default AdaptFilePreview;
