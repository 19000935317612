import React from 'react';
import ContentLoader from 'react-content-loader';

type Props = {
  height?: string;
  compact?: boolean;
};

const FieldLoader = (props: Props) => {
  const { height, compact } = props;
  const ht = compact ? '40' : height;
  return (
    <div className="adapt-field-loader" style={{ height: ht }}>
      <ContentLoader speed={2} style={{ width: '100%', height: '100%' }}>
        <rect x="0" y="0" rx={6} ry={6} width="100%" height="100%" />
      </ContentLoader>
    </div>
  );
};

export default FieldLoader;
